import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getRestaurantClients } from '../actions/userActions';
import SubMenu from '../components/SubMenu';
import ShowCustomerInfo from '../components/ShowCustomerInfo';
import {
  Card,
  Button,
  Row,
  Col,
  InputGroup,
  FormControl,
  Pagination,
  Spinner,
  Badge,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPhone, faCopy, faEye } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ContainerBox, 
  Header, 
  Body, OrangeButton, 
   Container,
   CardHeader, CardHeaderTitle} from '../components/DefaultImports'

const ITEMS_PER_PAGE = 10;

function RestaurantClients() {
  const params = useParams();
  const dispatch = useDispatch();
  const restaurant_id = params.id;

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [isShowCustomerInfoModalActive, setIsShowCustomerInfoModalActive] = useState(false);
  const [completePhone, setCompletePhone] = useState('');

  const clients = useSelector((state) => state.restaurant_clients);
  const { restaurantClients, isLoadingGetRestaurantClients } = clients;

  const totalPages = restaurantClients
    ? Math.ceil(restaurantClients.total_results / ITEMS_PER_PAGE)
    : 0;

  useEffect(() => {
    dispatch(getRestaurantClients(restaurant_id, currentPage, debouncedSearchTerm));
  }, [dispatch, restaurant_id, currentPage, debouncedSearchTerm]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setCurrentPage(1); // Reset to the first page when the search term changes
    }, 1000);
    return () => clearTimeout(handler);
  }, [searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Número copiado para a área de transferência!', { autoClose: 1000 });
  };

  const showCustomerInfo = (phone_number) => {
    setCompletePhone(phone_number);
    setIsShowCustomerInfoModalActive(true);
  };

  const paginatedResults = restaurantClients
    ? restaurantClients.results
    : [];

  return (
    <Container className="my-4">
      <ToastContainer />
      <Row>
        <Col md={12}>
          <CardHeader>
            <CardHeaderTitle>
            Clientes
            </CardHeaderTitle>
          </CardHeader>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Procurar cliente"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
          </InputGroup>
          <div className="d-flex flex-column justify-content-center align-items-center mt-3 text-center">
            <span className="mb-2">
              Página {currentPage} de {totalPages} ({restaurantClients?.total_results || 0} resultados)
            </span>
            <Pagination>
              <Pagination.Prev disabled={currentPage === 1} onClick={handlePrevPage}>
                Anterior
              </Pagination.Prev>
                <Pagination.Item
                >
                  {currentPage}
                </Pagination.Item>
              <Pagination.Next disabled={currentPage === totalPages} onClick={handleNextPage}>
                Próxima
              </Pagination.Next>
            </Pagination>
          </div>
          {isLoadingGetRestaurantClients ? (
            <div className="text-center my-4">
              <Spinner animation="border" />
              <p>Carregando clientes...</p>
            </div>
          ) : (
            <div>
              {paginatedResults && paginatedResults.length > 0 ? (
                paginatedResults.map((client) => (
                  <Card className="mb-3 shadow-sm" key={client.id}>
                    <Card.Body>
                      <Row>
                        <Col md={6} className="d-flex align-items-center" style={{cursor:'pointer'}} >
                          <FontAwesomeIcon
                            icon={faEye}
                            className="me-2 text-primary cursor-pointer"
                            onClick={() => showCustomerInfo(client.client.phone_number)}
                                                     />
                          <strong>{client.client.name}</strong>
                        </Col>
                        <Col md={3} className="d-flex align-items-center">
                          <a href={`tel:${client.client.phone_number}`} className="me-2 text-success">
                            <FontAwesomeIcon icon={faPhone} />
                          </a>
                          +{client.client.phone_number}
                          <FontAwesomeIcon
                            icon={faCopy}
                            className="ms-2 text-secondary cursor-pointer"
                            onClick={() => copyToClipboard(client.client.phone_number)}
                          />
                        </Col>
                        <Col md={3} className="d-flex justify-content-end">
                          <Badge bg="success" className="me-2">
                            Reservas: {client.number_of_reservations}
                          </Badge>
                          <Badge bg="danger">No Show: {client.did_not_show_up_times}</Badge>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ))
              ) : (
                <p className="text-center">Nenhum cliente encontrado.</p>
              )}
            </div>
          )}
          

        </Col>
      </Row>
      <ShowCustomerInfo
        phone_number={completePhone}
        restaurant_id={restaurant_id}
        isModalActive={isShowCustomerInfoModalActive}
        setIsModalActive={setIsShowCustomerInfoModalActive}
      />
    </Container>
  );
}

export default RestaurantClients;
