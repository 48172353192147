import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar, Nav, Container, Row, Col, Button } from 'react-bootstrap';
import { logout } from '../actions/userActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { getRestaurantSubscription } from '../actions/userActions';
import LogoImage from '../icons/logo.png'; 

import { getManager } from '../actions/managersActions'
import SuspensionAccountWarning from './SuspensionAccountWarning'; 
import { faLock } from '@fortawesome/free-solid-svg-icons';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { faSignOut, faBars } from '@fortawesome/free-solid-svg-icons';
import { NavLink, useNavigate } from 'react-router-dom';  
import {  
  CalendarSvgIcon,
  FilterSvgIcon,
  GearSvgIcon,
  NoPhoneSvgIcon,
  OnOffSvgIcon,
  PhoneSvgIcon,
  PlusSvgIcon,
  ReservationSvgIcon,
  TableSvgIcon,
  UsersSvgIcon,
  XSvgIcon,
  UserSvgIcon,
  ClockSvgIcon,
  BellSvgIcon,
  BubbleTextSvgIcon,
  ZoneSvgIcon,
} from '../icons/SvgIcons';
import { colors } from '@mui/material';

// Sidebar for large screens / Top header for small screens
const Sidebar = styled.aside`
  position: static;
  top: 0;
  left: 0;
  height: 100vh;
  min-width: 300px;
  background-color: #FAF6E9;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  justify-content: space-between;

  @media (max-width: 768px) {
    position: fixed;
    height: 60px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100px;
  margin-top: 50px;
  font-size: 2rem; /* Correct font size */
  color: #FF5733;
  font-weight: bold;

  @media (max-width: 768px) {
    width: 50px;
    height: 30px;
    margin-top: 0;
    margin-left:20px;
  }
`;

const Logo = styled.img`
  height: 80px;
  margin-right: 10px;

  @media (max-width: 768px) { /* Correct media query */
    height: 50px;
    margin-right: 5px; /* Optional: Adjust margin for smaller screens */
  }
`;

const Menu = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 1.5rem;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    flex-direction: column;
      gap: 0.5rem;
  }
`;

const ShowBigScreen = styled.div`
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MenuLink = styled(NavLink)`
  color: #4B3335; /* Default text color */
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  border: 1px solid transparent; /* Default border (none) */
  border-radius: 8px;
  padding: 5px;
  width: 190px;
  gap: 10px;

  /* Active State */
  &.active {
    border-color: #EE754C; /* Active border */
    color: #EE754C; /* Active text color */
    font-weight: bold;

    svg {
      stroke: #EE754C !important; /* Active SVG color */
    }
  }

  /* Hover effect */
  &:hover {
    color: #EE754C;

    svg {
      stroke: #EE754C;
    }
  }
`;

const Footer = styled.div`
  padding: 1rem;
  padding-left: 3rem;
  margin-bottom:1rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  background-color: #FF751A;
  border: none;
  font-size: 1rem;
  color: white;

  &:hover {
    background-color: #E64A00;
  }
`;

const MobileMenuButton = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
    font-size: 1.5rem;
    color: #FF5733;
  }
`;

const MobileMenuContainer = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  background-color: #FAF6E9;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  padding: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    display: none;
  }
`;

// Styled Circle Container
const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FF751A; /* Orange background */
  border-radius: 50%; /* Makes it a circle */
  width: 50px; /* Circle size */
  height: 50px; /* Circle size */
`;




const RestaurantMenuLinks = ({ restaurant_id, restaurants, managerInfo, setShowMenu  }) => {
  const [activeLink, setActiveLink] = useState(""); // State to track active link

  const handleLinkClick = (link) => {
    setActiveLink(link); // Update the active link state
    setShowMenu(false); 
  };

  // Helper function for disabled links
  const getLinkStyles = (link, condition) => ({
    pointerEvents: condition ? "none" : "auto",
    cursor: condition ? "default" : "pointer",
    border: activeLink === link ? "1px solid #EE754C" : "1px solid transparent",
    color: activeLink === link ? "#EE754C" : "#4B3335",
  });

  // Helper function to render lock icon
  const LockIcon = ({ condition }) =>
    condition ? (
      <FontAwesomeIcon icon={faLock} style={{ color: "#858585", marginLeft: "20px" }} />
    ) : null;

  return (
    <>
      {/* Restaurantes */}
      <div onClick={() => handleLinkClick("home")}>
        <MenuLink to="/" style={getLinkStyles("home", false)}>
          <ReservationSvgIcon
            width={20}
            height={20}
            strokeColor={activeLink === "home" ? "#EE754C" : "#4B3335"}
          />
          Restaurantes
        </MenuLink>
      </div>

      {restaurants && (
        <>
          {/* Reservas */}
          <div onClick={() => handleLinkClick("reservas")}>
            <MenuLink
              to={`reservationfromrestaurant/${restaurant_id}/null/filter`}
              style={getLinkStyles("reservas", false)}
            >
              <ReservationSvgIcon
                width={20}
                height={20}
                strokeColor={activeLink === "reservas" ? "#EE754C" : "#4B3335"}
              />
              Reservas
            </MenuLink>
          </div>

          {/* Horários */}
          <div onClick={() => handleLinkClick("horarios")}>
            <MenuLink
              to={`/time/${restaurant_id}`}
              style={getLinkStyles("horarios", managerInfo && !managerInfo.edit_closed_days)}
            >
              <ClockSvgIcon
                width={20}
                height={20}
                strokeColor={activeLink === "horarios" ? "#EE754C" : "#4B3335"}
              />
              Horários
              <LockIcon condition={managerInfo && !managerInfo.edit_closed_days} />
            </MenuLink>
          </div>

          {/* Zonas e Mesas */}
          <div onClick={() => handleLinkClick("zonas")}>
            <MenuLink
              to={`/RestaurantZonesTablesManagement/${restaurant_id}`}
              style={getLinkStyles("zonas", managerInfo && !managerInfo.edit_restaurant_settings)}
            >
              <ZoneSvgIcon
                width={20}
                height={20}
                strokeColor={activeLink === "zonas" ? "#EE754C" : "#4B3335"}
              />
              Zonas e Mesas
              <LockIcon condition={managerInfo && !managerInfo.edit_restaurant_settings} />
            </MenuLink>
          </div>

          {/* Clientes */}
          <div onClick={() => handleLinkClick("clientes")}>
            <MenuLink
              to={`/restaurantclients/${restaurant_id}`}
              style={getLinkStyles("clientes", managerInfo && !managerInfo.edit_restaurant_settings)}
            >
              <UsersSvgIcon
                width={25}
                height={20}
                strokeColor={activeLink === "clientes" ? "#EE754C" : "#4B3335"}
              />
              Clientes
              <LockIcon condition={managerInfo && !managerInfo.edit_restaurant_settings} />
            </MenuLink>
          </div>

          {/* Notificações */}
          <div onClick={() => handleLinkClick("notificacoes")}>
            <MenuLink
              to={`/restaurant_notifications/${restaurant_id}`}
              style={getLinkStyles(
                "notificacoes",
                managerInfo && !managerInfo.edit_restaurant_settings
              )}
            >
              <BellSvgIcon
                width={20}
                height={20}
                strokeColor={activeLink === "notificacoes" ? "#EE754C" : "#4B3335"}
              />
              Notificações
              <LockIcon condition={managerInfo && !managerInfo.edit_restaurant_settings} />
            </MenuLink>
          </div>

          {/* Definições */}
          <div onClick={() => handleLinkClick("definicoes")}>
            <MenuLink
              to={`/restaurantsettingsupdate/${restaurant_id}`}
              style={getLinkStyles("definicoes", managerInfo && !managerInfo.edit_restaurant_settings)}
            >
              <GearSvgIcon
                width={20}
                height={20}
                strokeColor={activeLink === "definicoes" ? "#EE754C" : "#4B3335"}
              />
              Definições
              <LockIcon condition={managerInfo && !managerInfo.edit_restaurant_settings} />
            </MenuLink>
          </div>

          {/* Info */}
          <div onClick={() => handleLinkClick("info")}>
            <MenuLink
              to={`/restaurant_info_update/${restaurant_id}`}
              style={getLinkStyles("info", managerInfo && !managerInfo.edit_restaurant_settings)}
            >
              <GearSvgIcon
                width={20}
                height={20}
                strokeColor={activeLink === "info" ? "#EE754C" : "#4B3335"}
              />
              Informações
              <LockIcon condition={managerInfo && !managerInfo.edit_restaurant_settings} />
            </MenuLink>
          </div>
        </>
      )}

      {/* Colaboradores */}
      {!managerInfo && (
        <div onClick={() => handleLinkClick("colaboradores")}>
          <MenuLink to="/manager_account_creation" style={getLinkStyles("colaboradores", false)}>
            <UsersSvgIcon
              width={25}
              height={20}
              strokeColor={activeLink === "colaboradores" ? "#EE754C" : "#4B3335"}
            />
            Membros da equipa
          </MenuLink>
        </div>
      )}
    </>
  );
};





function Header({}) {
  const [showMenu, setShowMenu] = useState(false); // State for mobile menu toggle

  const toggleMenu = () => setShowMenu(!showMenu);

    const dispatch = useDispatch();

    const navigate = useNavigate();
  
    const [showHeader, setShowHeader] = useState(true);

    const [restaurant_id, setRestaurant_id] = useState('');
  
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
  
    const manager_info = useSelector(state => state.crudManager)
    const { managerInfo } = manager_info
  
    const restaurant_sub = useSelector((state) => state.restaurant_subscription);
    const { restaurantSubscription } = restaurant_sub;
  
    const restaurantDetails = useSelector(state => state.restaurantDetails);
    const { restaurants } = restaurantDetails;
  
    useEffect(() => {
      if (restaurantSubscription && restaurantSubscription[0].account_type === 'not_vip') {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }
    }, [restaurantSubscription]);
  
    useEffect(() => {
      dispatch(getRestaurantSubscription());
      dispatch(getManager());
    }, [dispatch]);


      
      useEffect(() => {
        if (restaurants) {
          setRestaurant_id(restaurants.id)
        } else {
          setRestaurant_id('');
        }
        
      },[restaurants])
  
  
    const logoutHandler = (e) => {
      e.preventDefault();
      dispatch(logout());
      setShowHeader(true);
    };

  return (
    <Sidebar>
      <HeaderTitle>
        <Logo src={LogoImage} alt="Logo"/>
      </HeaderTitle>

      {/* Mobile Menu Button */}
      <MobileMenuButton onClick={toggleMenu}>
        <FontAwesomeIcon icon={faBars} />
      </MobileMenuButton>

      {/* Menu Links */}
      <MobileMenuContainer show={showMenu}>
        <Menu>
          <RestaurantMenuLinks 
            restaurant_id={restaurant_id} 
            restaurants={restaurants} 
            managerInfo={managerInfo} 
            setShowMenu={setShowMenu}
          />
        </Menu>
      </MobileMenuContainer>

      {/* Sidebar Menu for Large Screens */}
      <ShowBigScreen>
      
      <Menu>
        <RestaurantMenuLinks 
          restaurant_id={restaurant_id} 
          restaurants={restaurants} 
          managerInfo={managerInfo} 
          setShowMenu={setShowMenu}
        />
      </Menu>

         
      </ShowBigScreen>

      {/* Footer */}
      <Footer>
          
          <Link to={'/profile/update/no'}
          style={{flexWrap:'nowrap', 
                        display:'flex', 
                        justifyContent:'start',
                        alignItems:'center', 
                        marginLeft:'-10px', 
                        marginBottom:'10px',
                        textDecoration: 'none',
                        color:'black'
                       }}>
          <Circle style={{marginRight:'10px'}}>
              <UserSvgIcon strokeColor={'#ffffff'}  width={30} height={30} />
          </Circle>Conta
          </Link>


        <div onClick={(e) => logoutHandler(e)} style={{cursor:'pointer'}}><OnOffSvgIcon width={30} height={30} style={{marginRight:'20px'}}/> Sair</div>
        
      </Footer>
    </Sidebar>
  );
}

export default Header;
