import React from 'react';

export const TableIconSvg = ({
  width = 19.09,
  height = 29.85,
  style = {},
  strokeColor = '#5ace8c', // Default stroke color set to #f5bf42, can be overridden via props
}) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.09 29.85"
    width={width} // Default width from original SVG, can be overridden via props
    height={height} // Default height from original SVG, can be overridden via props
    style={style}
  >
    <defs>
      <style>
        {`.cls-1 {
          fill: none;
          stroke-miterlimit: 10;
          stroke-width: 1.5px;
        }`}
      </style>
    </defs>
    <rect className="cls-1" x=".75" y="6.78" width="17.59" height="16.28" rx="1.3" ry="1.3" style={{stroke:strokeColor}} />
    <path className="cls-1" d="M5.02,5.41C5.02,2.84,7.11.75,9.68.75s4.66,2.09,4.66,4.66" style={{stroke:strokeColor}} />
    <path className="cls-1" d="M14.07,24.43c0,2.57-2.09,4.66-4.66,4.66s-4.66-2.09-4.66-4.66" style={{stroke:strokeColor}} />
  </svg>
);

export const ReservationSvgIcon = ({
  width = 23.68, // Default width from original SVG
  height = 29.85, // Default height from original SVG
  style = {},
  strokeColor = '#433034', // Default stroke color, can be overridden via props
}) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23.68 29.85"
    width={width}
    height={height}
    style={style}
  >
    <defs>
      <style>
        {`.cls-1 {
          fill: none;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
        }`}
      </style>
    </defs>
    <rect className="cls-1" x=".75" y=".75" width="22.18" height="28.35" style={{stroke:strokeColor}} />
    <line className="cls-1" x1="6.91" y1="8.76" x2="16.77" y2="8.76" style={{stroke:strokeColor}} />
    <line className="cls-1" x1="6.91" y1="14.92" x2="16.77" y2="14.92" style={{stroke:strokeColor}} />
    <line className="cls-1" x1="6.91" y1="21.09" x2="16.77" y2="21.09" style={{stroke:strokeColor}} />
  </svg>
);

export const ClockSvgIcon = ({
  width = 29.85, // Default width from original SVG
  height = 29.85, // Default height from original SVG
  style = {},
  strokeColor = '#433034', // Default stroke color, can be overridden via props
}) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29.85 29.85"
    width={width}
    height={height}
    style={style}
  >
    <defs>
      <style>
        {`.cls-1 {
          fill: none;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
        }`}
      </style>
    </defs>
    <circle className="cls-1" cx="14.92" cy="14.92" r="14.17" style={{stroke:strokeColor}} />
    <polyline className="cls-1" points="14.92 5.06 14.92 14.92 19.85 19.85" style={{stroke:strokeColor}} />
  </svg>
);


export const ZoneSvgIcon = ({
  width = 29.85, // Default width from original SVG
  height = 29.85, // Default height from original SVG
  style = {},
  strokeColor = '#433034', // Default stroke color, can be overridden via props
}) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29.85 29.85"
    width={width}
    height={height}
    style={style}
  >
    <defs>
      <style>
        {`.cls-1 {
          fill: none;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
        }`}
      </style>
    </defs>
    <line className="cls-1" x1="4.51" y1=".75" x2="4.51" y2="25.25" style={{stroke:strokeColor}} />
    <line className="cls-1" x1=".75" y1="4.51" x2="25.25" y2="4.51" style={{stroke:strokeColor}} />
    <line className="cls-1" x1="25.34" y1="4.59" x2="25.34" y2="29.1" style={{stroke:strokeColor}} />
    <line className="cls-1" x1="4.59" y1="25.34" x2="29.1" y2="25.34" style={{stroke:strokeColor}} />
  </svg>
);

export const UserSvgIcon = ({
  width = 29.85, // Default width from original SVG
  height = 29.85, // Default height from original SVG
  style = {},
  strokeColor = '#433034', // Default stroke color, can be overridden via props
}) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29.85 29.85"
    width={width}
    height={height}
    style={style}
  >
    <defs>
      <style >
        {`.cls-1 {
          fill: none;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
        }`}
      </style>
    </defs>
    <circle className="cls-1" cx="14.92" cy="6.91" r="6.16" style={{stroke:strokeColor}}/>
    <path
      className="cls-1"
      d="M29.1,29.1H.75c0-6.81,5.52-12.32,12.32-12.32h3.7c6.81,0,12.32,5.52,12.32,12.32h0Z"
      style={{stroke:strokeColor}} 
      
    />
  </svg>
);

export const BellSvgIcon = ({
  width = 27.38, // Default width from original SVG
  height = 29.85, // Default height from original SVG
  style = {},
  strokeColor = '#433034', // Default stroke color, can be overridden via props
}) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27.38 29.85"
    width={width}
    height={height}
    style={style}
  >
    <defs>
      <style>
        {`.cls-1 {
          fill: none;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
        }`}
      </style>
    </defs>
    <path
      className="cls-1"
      d="M24.48,15.07c0,4.1,2.15,9.1,2.15,9.1H.75s2.12-5,2.12-9.1C2.87,7.17,6.98.75,13.69.75s10.79,6.42,10.79,14.32Z"
      style={{stroke:strokeColor}} 
    />
    <circle className="cls-1" cx="13.69" cy="27.25" r="1.85" style={{stroke:strokeColor}} />
  </svg>
);

export const OnOffSvgIcon = ({
  width = 29.91, // Default width from original SVG
  height = 29.85, // Default height from original SVG
  style = {},
  strokeColor = '#433034', // Default stroke color, can be overridden via props
}) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29.91 29.85"
    width={width}
    height={height}
    style={style}
  >
    <defs>
      <style>
        {`.cls-1 {
          fill: none;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
        }`}
      </style>
    </defs>
    <path
      className="cls-1"
      d="M21.41,2.25c4.6,2.34,7.75,7.12,7.75,12.64,0,7.85-6.35,14.2-14.2,14.2S.75,22.74.75,14.89C.75,9.36,3.9,4.59,8.5,2.25"
      style={{stroke:strokeColor}} 
    />
    <line className="cls-1" x1="14.95" y1=".75" x2="14.95" y2="15.51" style={{stroke:strokeColor}} />
  </svg>
);

export const BubbleTextSvgIcon = ({
  width = 51.45, // Default width from original SVG
  height = 30.54, // Default height from original SVG
  style = {},
  strokeColor = '#433034', // Default stroke color, can be overridden via props
}) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 51.45 30.54"
    width={width}
    height={height}
    style={style}
  >
    <defs>
      <style>
        {`.cls-1 {
          fill: none;
          stroke-miterlimit: 10;
          stroke-width: 1.5px;
        }`}
      </style>
    </defs>
    <path
      className="cls-1"
      d="M47.6,23.83l2.37,5.27-5.89-2.53c-2.71,1.56-6.05,2.53-9.76,2.53-1.96,0-3.73-.19-5.49-.78,6.27-2.53,10.57-7.61,10.57-13.47,0-4.3-2.15-8.01-5.49-10.76h.41c8.98,0,16.23,5.68,16.23,12.51,0,2.74-.98,5.08-2.95,7.23h0ZM37.05,14.66h0c0-3.74-1.95-7.26-5.1-9.6-3.31-2.75-7.83-4.3-12.91-4.3C9.06.75.84,7.02.84,14.66c0,2.9,1.17,5.83,3.13,7.98l-2.56,6.08,6.67-2.93c3.12,1.93,6.83,2.93,10.95,2.93,2.93,0,5.86-.59,8.42-1.56,5.67-2.37,9.59-7.05,9.59-12.51h0Z"
      style={{stroke:strokeColor}} 
    />
  </svg>
);

export const TableSvgIcon = ({
  width = 19.09, // Default width from original SVG
  height = 29.85, // Default height from original SVG
  style = {},
  strokeColor = '#433034', // Default stroke color, can be overridden via props
}) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.09 29.85"
    width={width}
    height={height}
    style={style}
  >
    <defs>
      <style>
        {`.cls-1 {
          fill: none;
          stroke-miterlimit: 10;
          stroke-width: 1.5px;
        }`}
      </style>
    </defs>
    <rect className="cls-1" x=".75" y="6.78" width="17.59" height="16.28" rx="1.3" ry="1.3" style={{stroke:strokeColor}} />
    <path className="cls-1" d="M5.02,5.41C5.02,2.84,7.11.75,9.68.75s4.66,2.09,4.66,4.66" style={{stroke:strokeColor}} />
    <path className="cls-1" d="M14.07,24.43c0,2.57-2.09,4.66-4.66,4.66s-4.66-2.09-4.66-4.66" style={{stroke:strokeColor}} />
  </svg>
);

export const UsersSvgIcon = ({
  width = 29.85, // Default width
  height = 50, // Default height
  style = {},
  strokeColor = '#433034', // Default stroke color
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48.24 29.85" // Matches the original dimensions
    width={width} // Dynamically sets the width
    height={height} // Dynamically sets the height
    style={style} // Allows inline styling overrides
    preserveAspectRatio="xMidYMid meet" // Maintains aspect ratio
  >
    <defs>
      <style>
        {`.cls-1 {
          fill: none;
          stroke-miterlimit: 10;
          stroke-width: 1.5px;
        }`}
      </style>
    </defs>
    <circle className="cls-1" cx="24.16" cy="8.66" r="5.68" style={{stroke:strokeColor}} />
    <circle className="cls-1" cx="10.73" cy="5.09" r="4.34" style={{stroke:strokeColor}} />
    <circle className="cls-1" cx="37.51" cy="5.09" r="4.34" style={{stroke:strokeColor}} />
    <path
      className="cls-1"
      d="M33.46,20.71h14.03c0-4.79-3.89-8.68-8.68-8.68h-2.6c-3.82,0-7.04,2.49-8.19,5.93-.7-.13-1.42-.22-2.16-.22h-3.41c-.77,0-1.51.08-2.23.23-1.15-3.44-4.36-5.94-8.19-5.94h-2.6C4.63,12.03.75,15.92.75,20.71h14.11c-2.29,2.08-3.76,5.05-3.76,8.39h26.12c0-3.34-1.47-6.31-3.76-8.39Z"
      style={{stroke:strokeColor}} 
    />
    <path
      className="cls-1"
      d="M37.22,29.1H11.1c0-6.27,5.08-11.36,11.36-11.36h3.41c6.27,0,11.36,5.08,11.36,11.36h0Z"
      style={{stroke:strokeColor}} 
    />
  </svg>
);

export const PlusSvgIcon = ({
  width = 28.35, // Default width from original SVG
  height = 28.35, // Default height from original SVG
  style = {},
  strokeColor = '#433034', // Default stroke color, can be overridden via props
}) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28.35 28.35"
    width={width}
    height={height}
    style={style}
  >
    <defs>
      <style>
        {`.cls-1 {
          fill: none;
          stroke-miterlimit: 10;
          stroke-width: 1.5px;
        }`}
      </style>
    </defs>
    <line className="cls-1" y1="14.17" x2="28.35" y2="14.17" style={{stroke:strokeColor}} />
    <line className="cls-1" x1="14.17" y1="28.35" x2="14.17" style={{stroke:strokeColor}} />
  </svg>
);

export const PhoneSvgIcon = ({
  width = 29.85, // Default width from original SVG
  height = 29.85, // Default height from original SVG
  style = {},
  strokeColor = '#433034', // Default stroke color, can be overridden via props
}) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29.85 29.85"
    width={width}
    height={height}
    style={style}
  >
    <defs>
      <style>
        {`.cls-1 {
          fill: none;
          stroke-miterlimit: 10;
          stroke-width: 1.5px;
        }`}
      </style>
    </defs>
    <path
      className="cls-1"
      d="M28.93,24.44l-1.33,2.53c-.25.48-.62.89-1.08,1.19-3.92,2.55-11.74-.23-18.17-6.66C1.92,15.06-.86,7.25,1.69,3.32c.3-.46.71-.83,1.19-1.08l2.53-1.33c.6-.31,1.34-.17,1.77.35l3.93,4.75c.47.57.43,1.4-.09,1.92l-1.52,1.52c-.3.3-.38.76-.2,1.15.49,1.05,1.7,3.23,4.2,5.73,2.5,2.5,4.68,3.71,5.73,4.2.39.18.85.1,1.15-.2l1.52-1.52c.52-.52,1.36-.56,1.92-.09l4.75,3.93c.52.43.67,1.17.35,1.77Z"
      style={{stroke:strokeColor}} 
    />
  </svg>
);

export const NoPhoneSvgIcon = ({
  width = 29.85, // Default width from original SVG
  height = 29.85, // Default height from original SVG
  style = {},
  strokeColor = '#433034', // Default stroke color, can be overridden via props
}) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29.85 29.85"
    width={width}
    height={height}
    style={style}
  >
    <defs>
      <style>
        {`.cls-1 {
          fill: none;
          stroke-miterlimit: 10;
          stroke-width: 1.5px;
        }`}
      </style>
    </defs>
    <path
      className="cls-1"
      d="M28.93,24.44l-1.33,2.53c-.25.48-.62.89-1.08,1.19-3.92,2.55-11.74-.23-18.17-6.66C1.92,15.06-.86,7.25,1.69,3.32c.3-.46.71-.83,1.19-1.08l2.53-1.33c.6-.31,1.34-.17,1.77.35l3.93,4.75c.47.57.43,1.4-.09,1.92l-1.52,1.52c-.3.3-.38.76-.2,1.15.49,1.05,1.7,3.23,4.2,5.73,2.5,2.5,4.68,3.71,5.73,4.2.39.18.85.1,1.15-.2l1.52-1.52c.52-.52,1.36-.56,1.92-.09l4.75,3.93c.52.43.67,1.17.35,1.77Z"
      style={{stroke:strokeColor}} 
    />
    <line className="cls-1" x1="1.21" y1="28.58" x2="26.89" y2="2.91" style={{stroke:strokeColor}} />
  </svg>
);

export const FilterSvgIcon = ({
  width = 31.01, // Default width from original SVG
  height = 29.85, // Default height from original SVG
  style = {},
  strokeColor = '#433034', // Default stroke color, can be overridden via props
}) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31.01 29.85"
    width={width}
    height={height}
    style={style}
  >
    <defs>
      <style>
        {`.cls-1 {
          fill: none;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
        }`}
      </style>
    </defs>
    <polygon
      className="cls-1"
      points="30.26 5.63 18.09 20 18.09 29.1 13.11 27.63 13.11 20 .75 5.63 .75 .75 15.76 .75 30.26 .75 30.26 5.63"style={{stroke:strokeColor}} 
    />
  </svg>
);

export const CalendarSvgIcon = ({
  width = 29.72, // Default width from original SVG
  height = 30.1, // Default height from original SVG
  style = {},
  strokeColor = '#433034', // Default stroke color, can be overridden via props
  fillColor = '#433034', // Default fill color for the calendar header
}) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29.72 30.1"
    width={width}
    height={height}
    style={style}
  >
    <defs>
      <style>
        {`
          .cls-1 {
            stroke-width: 2px;
          }
          .cls-1, .cls-2 {
            fill: none;
            stroke-linecap: round;
            stroke-linejoin: round;
          }
          .cls-2 {
            stroke-width: 1.5px;
          }
          .cls-3 {
            fill: ${strokeColor};
            stroke-width: 0px;
          }
        `}
      </style>
    </defs>
    <rect className="cls-2" x=".81" y="11.53" width="28.1" height="17.82" style={{stroke:strokeColor}} />
    <rect className="cls-3" y="2.92" width="29.72" height="6.46" rx=".93" ry=".93" style={{stroke:strokeColor}} />
    <line className="cls-1" x1="26.27" y1="1" x2="26.27" y2="6.67" style={{stroke:strokeColor}} />
    <line className="cls-1" x1="3.85" y1="1" x2="3.85" y2="6.67" style={{stroke:strokeColor}} />
  </svg>
);

export const XSvgIcon = ({
  width = 29.41, // Default width from original SVG
  height = 29.41, // Default height from original SVG
  style = {},
  strokeColor = '#433034', // Default stroke color, can be overridden via props
}) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29.41 29.41"
    width={width}
    height={height}
    style={style}
  >
    <defs>
      <style>
        {`.cls-1 {
          fill: none;
          stroke-miterlimit: 10;
          stroke-width: 1.5px;
        }`}
      </style>
    </defs>
    <line className="cls-1" x1=".53" y1=".53" x2="28.88" y2="28.88" style={{stroke:strokeColor}} />
    <line className="cls-1" x1=".53" y1="28.88" x2="28.88" y2=".53" style={{stroke:strokeColor}} />
  </svg>
);

export const GearSvgIcon = ({
  width = 29.85, // Default width from original SVG
  height = 29.85, // Default height from original SVG
  style = {},
  strokeColor = '#433034', // Default stroke color, can be overridden via props
}) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29.85 29.85"
    width={width}
    height={height}
    style={style}
  >
    <defs>
      <style>
        {`.cls-1 {
          fill: none;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
        }`}
      </style>
    </defs>
    <circle className="cls-1" cx="14.92" cy="14.92" r="6.16" style={{stroke:strokeColor}} />
    <path
      className="cls-1"
      d="M5.06,20.47l-2.46,2.46,4.31,4.31,2.46-2.46c.86.49,1.49.97,2.46,1.23v3.08h6.16v-3.08c.97-.26,1.61-.74,2.46-1.23l2.46,2.46,4.31-4.31-2.46-2.46c.49-.86.97-1.49,1.23-2.46h3.08v-6.16h-3.08c-.26-.97-.74-1.61-1.23-2.46l2.46-2.46-4.31-4.31-2.46,2.46c-.86-.49-1.49-.97-2.46-1.23V.75h-6.16v3.08c-.97.26-1.61.74-2.46,1.23l-2.46-2.46L2.6,6.91l2.46,2.46c-.49.86-.97,1.49-1.23,2.46H.75v6.16h3.08c.26.97.74,1.61,1.23,2.46Z"
      style={{stroke:strokeColor}} 
    />
  </svg>
);

export const PennotebookSvgIcon = ({
  width = 29.85, // Default width
  height = 29.85, // Default height
  style = {},
  strokeColor = '#433034', // Default stroke color
}) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29.85 29.85"
    width={width}
    height={height}
    style={style}
  >
    <defs>
      <style>
        {`.cls-1 {
          fill: none;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
        }`}
      </style>
    </defs>
    {/* Notebook */}
    <rect
      className="cls-1"
      x="6"
      y="4"
      width="17.85"
      height="21.85"
      rx="2"
      ry="2"
      style={{ stroke: strokeColor }}
    />
    <line
      className="cls-1"
      x1="10"
      y1="7"
      x2="19.85"
      y2="7"
      style={{ stroke: strokeColor }}
    />
    <line
      className="cls-1"
      x1="10"
      y1="10.5"
      x2="19.85"
      y2="10.5"
      style={{ stroke: strokeColor }}
    />
    <line
      className="cls-1"
      x1="10"
      y1="14"
      x2="19.85"
      y2="14"
      style={{ stroke: strokeColor }}
    />
    <line
      className="cls-1"
      x1="10"
      y1="17.5"
      x2="19.85"
      y2="17.5"
      style={{ stroke: strokeColor }}
    />
    <line
      className="cls-1"
      x1="10"
      y1="21"
      x2="19.85"
      y2="21"
      style={{ stroke: strokeColor }}
    />
    {/* Pen */}
    <path
      className="cls-1"
      d="M21.5,22.5l3.5-3.5c.39-.39.39-1.02,0-1.41l-2.1-2.1c-.39-.39-1.02-.39-1.41,0l-3.5,3.5"
      style={{ stroke: strokeColor }}
    />
    <line
      className="cls-1"
      x1="19"
      y1="23"
      x2="21.5"
      y2="22.5"
      style={{ stroke: strokeColor }}
    />
    <line
      className="cls-1"
      x1="23"
      y1="20"
      x2="24.5"
      y2="18.5"
      style={{ stroke: strokeColor }}
    />
  </svg>
);

export const ExtrasSvgIcon = ({
  width = 29.85, // Default width
  height = 29.85, // Default height
  style = {},
  strokeColor = '#433034', // Default stroke color
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29.85 29.85"
    width={width}
    height={height}
    style={style}
  >
    <defs>
      <style>
        {`.cls-1 {
          fill: none;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
        }`}
      </style>
    </defs>
    {/* Outer Starburst Shape */}
    <path
      className="cls-1"
      style={{ stroke: strokeColor }}
      d="M14.92,2.25l2.5,5.7h6.4l-4.6,4,2.3,6.3-5.6-2.8-5.6,2.8,2.3-6.3-4.6-4h6.4l2.5-5.7Z"
    />
    {/* Inner Plus Symbol */}
    <path
      className="cls-1"
      style={{ stroke: strokeColor }}
      d="M14.92 10v4.92m0 0v4.92m0-4.92h4.92m-4.92 0h-4.92"
    />
  </svg>
);