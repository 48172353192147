import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col} from 'react-bootstrap';
import { api } from '../App'
import {
    fetchEstimatedTimeCustomersSpend,
    createEstimatedTimeCustomersSpend,
    patchEstimatedTimeCustomersSpend,
    fetchHoursEach,
} from '../actions/restaurantActions';
import Loader from './Loader';
import { Modal, Button } from 'react-bootstrap';
import SelectableTimeInputForEstimatedTime from './SelectableTimeInputForEstimatedTime'
import DropDownButton from '../components/DropDownButton';
import ScrollInputOptions from '../components/ScrollInputOptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faChair, faGear, faCircleXmark, faClock, faUser } from '@fortawesome/free-solid-svg-icons';
import {ContainerBox, Header, Body, OrangeButton, Title} from '../components/DefaultImports'
import {  
  XSvgIcon,
} from '../icons/SvgIcons';


const ModernButton = styled.button`
  padding: 5px 8px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }
`;


const CreateButton = styled(ModernButton)`
  position: absolute;
  top: 11px;
  right: 11px;
  background-color: #59d9d4;
  &:hover {
    background-color: #228a86;
  }
`;

const B2 = styled.div`
  background-color: #f8f9fa;
  position:relative;
  margin-bottom:10px;
  padding:10px;
  border-radius:5px;
`;

const B2Top = styled.div`
  background-color: #58c49f;
  height:30px;
  color:white;
  width:100%;
`;


const ZoneTitle = styled.h2`
  color:white;
  font-size:18px;
  padding: 5px;
`;

const DangerButton = styled(Button)`
  background-color: #ff704d;
  border-color: #ff704d;

  &:hover {
    background-color: #ff471a;
    border-color: #ff471a;
  }
`;

const RemoveButton = styled(DangerButton)`
  position: absolute;
  top: 2px;
  right: 1px;
  width: 20px;
  height: 20px;
  padding: 3px;
  font-size: 18px;
  text-align: center;
  line-height: 5px;
  
`;

const StyledInput = styled.input`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin: 5px 0;
    width: 100%; // or any other width you prefer

    // Add any other styles you desire
    &:focus {
        border-color: #007BFF; // for example, change border color on focus
        outline: none;
    }
`;

const EditButton = styled(ModernButton)`
  margin-left: 10px;
  background-color:#0fbd76;
  &:hover {
    background-color: #21d98e;
  }
`;

const LargeIconPlus = styled(FontAwesomeIcon)`
  margin-left: 7px; /* adjust this value as needed */
`;


const EstimatedTimePage = ({restaurant_ids}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [options, setOptions] = useState(Array.from({length: 100}, (_, i) => i + 1));
  const [editingItem, setEditingItem] = useState(false);
  const [peopleNumer, setPeopleNumer] = useState(0);
  const [selectedTime, setSelectedTime] = useState('00:30');
  const [itemId, setItemId] = useState('');

  const [restaurant_id, setRestaurant_id] = useState(restaurant_ids);

  const estimatedTimes = useSelector(state => state.estimatedTimeCustomersSpend);
  const { estimatedTime } = estimatedTimes;

  //  state to store the input values
  const [inputValues, setInputValues] = useState({});
  const setTimeListWithHours = () => {
    let time = new Date(0, 0, 0, 0, 0, 0, 0); // Start at midnight
    let end = new Date(0, 0, 0, 10, 0, 0, 0); // End at 10:00
    let timeList = [];
  
    while(time <= end) {
        let hour = time.getHours().toString().padStart(2, "0");
        let minutes = time.getMinutes().toString().padStart(2, "0");
  
        timeList.push(`${hour}:${minutes}`);
        time.setMinutes(time.getMinutes() + 15);
    }
  
    return timeList;
  }
const [timeList, setTimeList] = useState(setTimeListWithHours());

const hoursEachTime = useSelector((state) => state.hoursEachTime);
const {hoursEach} = hoursEachTime

  useEffect(() => {
    dispatch(fetchEstimatedTimeCustomersSpend(restaurant_id));
    dispatch(fetchHoursEach(restaurant_id))
  }, []);


  const handleDelete = async (id) => {
    try {
      const response = await api.delete(
        `/restaurants/${restaurant_id}/estimatedtime/${id}`
      );
      // Handle the response here (e.g., update your component's state)
      console.log(response.data);
    } catch (error) {
      // Handle the error here (e.g., display an error message)
      console.error(error);
    }
    dispatch(fetchEstimatedTimeCustomersSpend(restaurant_id));
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleCreate = () => {
    // dispatch action to create new item
    const params = {
      restaurant: restaurant_id,
      number_of_people: peopleNumer,
      estimated_time: selectedTime,
    }
    dispatch(createEstimatedTimeCustomersSpend(restaurant_id, params));
    // close the modal
    setModalIsOpen(false);
    // clear form
    
  };



  const handleOpenEditModal = (people, time, id) => {
    setItemId(id);
    setPeopleNumer(people);
    setSelectedTime(time);
    setEditingItem(true);
  };
  const handleEditSave = () => {
      const params = {
        number_of_people: peopleNumer,
        estimated_time: selectedTime,
      }
      dispatch(patchEstimatedTimeCustomersSpend(restaurant_id, itemId, params));

    setEditingItem(false); // Close the edit modal
  };
  const handlePeopleChange = (value) => {
    setPeopleNumer(value);
  };
  const handleSelectedTime = (value) => {
    setSelectedTime(value);

  };
  
  return (
    <ContainerBox>
      <Header>
        <Title>Tempo estimado</Title>
        <OrangeButton onClick={() => setModalIsOpen(true)}>Adicionar<LargeIconPlus size="xl" icon={faPlus} /></OrangeButton>
      </Header>
      <Body>
      {Array.isArray(estimatedTime) ? (
          <div>
            {estimatedTime.map((item) => (
              <B2 key={item.id}>
                  <Row style={{width: '100%'}}>
                    <Col xs={3}>
                      <h6> 
                        <FontAwesomeIcon size='xl' icon={faUser} style={{marginRight: '10px'}}/>
                        {item.number_of_people}
                      </h6> 
                    </Col>
                    <Col xs={5}>
                      <h6> 
                        <FontAwesomeIcon size='xl'  icon={faClock} style={{marginRight: '10px'}}/>
                        {item.estimated_time.slice(0,5)}
                      </h6> 
                    </Col>
                    <Col xs={4} className="d-flex justify-content-end">
                      <FontAwesomeIcon onClick={() => handleOpenEditModal( item.number_of_people, item.estimated_time.slice(0,5), item.id)} size='xl'  icon={faGear} style={{cursor: 'pointer'}}/>
                      <div style={{cursor:'pointer'}}  onClick={() => handleDelete(item.id)}>
                        <XSvgIcon             
                          width={20}
                          height={20}
                          strokeColor={"#99B0AD"}
                          />
                      </div>
                    </Col>
                  </Row>
              </B2>
            ))}
          </div>
        ) : (
          <Loader></Loader>
        )}

      </Body>
      
      
      <div>
        
      </div>

      <Modal
        show={modalIsOpen}
        onHide={() => setModalIsOpen(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Adicionar nova estimativa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={6}>
              {/* new stuff */}
              <DropDownButton
                options={options}
                handleOptionChange={handlePeopleChange}
                initialOption={peopleNumer}
              />
            </Col>
            <Col xs={6}>
                {Array.isArray(timeList) &&
                <ScrollInputOptions
                handleOptionsChange={handleSelectedTime}
                options={timeList}
                currentOption={selectedTime}
              />
              }
            </Col>
          </Row>
          
          
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalIsOpen(false)}>Fechar</Button>
          <Button variant="primary" onClick={handleCreate}>Adicionar</Button>
        </Modal.Footer>
      </Modal>
      {/* Modal for Editing an Item */}
      <Modal
        show={editingItem === true}
        onHide={() => setEditingItem(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Editar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row>
            <Col xs={6}>
              {/* new stuff */}
              <DropDownButton
                options={options}
                handleOptionChange={handlePeopleChange}
                initialOption={peopleNumer}
              />
            </Col>
            <Col xs={6}>
                {Array.isArray(timeList) &&
                <ScrollInputOptions
                handleOptionsChange={handleSelectedTime}
                options={timeList}
                currentOption={selectedTime}
              />
              }
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditingItem(false)}>Fechar</Button>
          <Button variant="primary" onClick={handleEditSave}>Guardar</Button>
        </Modal.Footer>
      </Modal>
    </ContainerBox>
  );
};

export default EstimatedTimePage;