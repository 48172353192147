import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import ClosedExceptions from '../components/ClosedExceptions';
import RestaurantTimeManagement from './RestaurantTimeManagement';
import RestaurantEstimatedTimes from './RestaurantEstimatedTimes';
import EstimatedTimePage from '../components/EstimatedTimeCustomersSpend';
import styled from 'styled-components';
import SubMenu from '../components/SubMenu';
import Separator from '../components/Separator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faChair, faGear, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import {ContainerBox, 
  Header, 
  Body, OrangeButton, 
  
  CardHeader, 
  CardHeaderTitle} from '../components/DefaultImports'

const Container = styled.div`
  max-width:1200px;
  height:100%;
  margin: 0 auto;
  padding:30px;
  padding-top:60px;

    @media (max-width: 768px) {
    padding:0px;
    padding-top:0;
    margin:0;
  }
`;

function Time() {


    const dispatch = useDispatch();
    const params = useParams();
    const [restaurant_id, setRestaurant_id] = useState(params.id);


  return (
    <Container>
        <CardHeader>
          <CardHeaderTitle>
          Horários
          </CardHeaderTitle>
        </CardHeader>
        <Row>
            <Col xs={12} md={12} xl={12}>
                <RestaurantTimeManagement restaurant_id={restaurant_id}/>
                <ClosedExceptions restaurant_id={restaurant_id}/>
                <EstimatedTimePage restaurant_ids={restaurant_id}/>
            </Col>
        </Row>
    </Container>
  )
}

export default Time