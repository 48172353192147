import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getRestaurantDetails } from '../actions/restaurantActions';
import  SwitchOnAndOff  from '../components/SwitchOnAndOff'
import Loader from '../components/Loader';
import Messages from '../components/Messages';
import styled from 'styled-components';
import SubMenu from '../components/SubMenu';
import { fetchRestaurantDetails, updateRestaurant, createRestaurant } from '../actions/restaurantActions';
import ScrollInputOptions from '../components/ScrollInputOptions';
import Separator from '../components/Separator';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import DropDownButton from '../components/DropDownButton'
import { GoogleMap, Marker, useLoadScript, useJsApiLoader  } from '@react-google-maps/api';
import 'react-phone-input-2/lib/style.css'; // Import the default CSS for react-phone-input-2
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'; // Import the Bootstrap CSS for additional styling
import { PhoneNumber } from 'libphonenumber-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePhoneFlip } from '@fortawesome/free-solid-svg-icons';
import {getDialCode, getCountryCode} from '../components/CountryCode';
/* global google */
import { isValidNumber } from 'libphonenumber-js';
import {ContainerBox, Header, Body, OrangeButton, Title, Container, CardHeader, CardHeaderTitle} from '../components/DefaultImports'

const Form = styled.form`
  max-width: 600px;
  margin: 0 auto;
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const InsideFormGroup = styled.div`
  width:100%;
  display: flex;
  flex:wrap;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;

  width: 60px; /* Initial size */
  min-width: 60px; /* Minimum size */
  max-width: max-content; /* Automatically expands to fit the content */
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`;




const Button = styled.button`
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #007bff;
  border: 1px solid #007bff;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 4px;

  &:hover {
    background-color: #0069d9;
    border-color: #0062cc;
  }
`;

const StyledSelect = styled.select`
  width: 100%;
  height: 2.5rem;
  font-size: 1rem;
  padding: 0.25rem;
  border: none;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
`;



const getTimezone = () => {
  if (window.Intl && window.Intl.DateTimeFormat) {
      const options = new window.Intl.DateTimeFormat().resolvedOptions();
      return options && options.timeZone ? options.timeZone : '';
  }
  return '';
}

function RestaurantSettingsUpdate({creating_restaurant='no', handleNextStep=false, restaurant_id=false, 
                                  restaurantCreated=false, setCreateRestaurantToFalse=false, steps=false}) {
  const dispatch = useDispatch();
  const params = useParams();
  const [restaurantId, setRestaurantId] = useState( restaurant_id ? restaurant_id : params.id );
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('ok_status');

  const [restaurantName, setRestaurantName] = useState('');
  const [address, setAddress] = useState('');
  const [postalCode, setPostalCode] = useState(false);

  const [longitudeField, setLongitudeField] = useState(0.000000);
  const [latitudeField, setLatitudeField] = useState(0.000000);
  const [viewDistance, setViewDistance] = useState(18);

  const [numberOfChairs, setNumberOfChairs] = useState(0);
  const [numberOfBabyChairs, setNumberOfBabyChairs] = useState('');
  const [limitPeopleReservation, setLimitPeopleReservation] = useState('');
  const [limitBabyChairsReservation, setLimitBabyChairsReservation] = useState('');
  const [autoAcceptLimit, setAutoAcceptLimit] = useState('');
  const [eachTime, setEachTime] = useState('00:15');
  const [acceptPeoplePerEachTime, setAcceptPeoplePerEachTime] = useState('');
  const [timezone, setTimezone] = useState(getTimezone());
  const timezones = moment.tz.names();
  const [autoAcceptTimeLimit, setAutoAcceptTimeLimit] = useState('');
  const [messageReminderTime, setMessageReminderTime] = useState('');
  const [autoSetTables, setAutoSetTables] = useState(null);
  const [timeToCancelledWarning, setTimeToCancelledWarning] = useState('');

  const [countryCode, setCountryCode] = useState('pt');
  const [countryCodeNumber, setCountryCodeNumber] = useState('351');
  const [phoneWithoutCountryCode, setPhoneWithoutCountryCode] = useState('');
  const [restaurant_description, setRestaurant_description] = useState('');
  const [reconfirmation_duration, setReconfirmation_duration] = useState('');
  const [reconfirmation_time_before_date, setReconfirmation_time_before_date] = useState('');

  const [restaurant_params, setRestaurant_params] = useState({});

  const { isLoaded: isGoogleLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyA7wQgzKIx0-y6j3pfy341jX6LmYq5_Sc4"
  });

  const [marker, setMarker] = useState(false);

  const [firstTimeChekcer, setFirstTimeChekcer] = useState(false);

  const [allowGeocode, setAllowGeocode] = useState(false);

  // Set the flag to true after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setAllowGeocode(true);
    }, 3000);  // 3000 milliseconds = 3 seconds
  
    // Cleanup function to clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);  // Empty dependency array means this effect only runs once on mount
  
  // Your existing useEffect, modified to also check for allowGeocode
  useEffect(() => {
    if (allowGeocode && postalCode && isGoogleLoaded) {
      console.log('blyat');
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: postalCode }, (results, status) => {
        if (status === 'OK') {
          const location = results[0].geometry.location;
          setLatitudeField(location.lat());
          setLongitudeField(location.lng());
          setMarker({
            lat: location.lat(),
            lng: location.lng(),
          });
        } else {
          console.error('Geocode was not successful for the following reason:', status);
        }
      });
    }
  }, [postalCode, isGoogleLoaded]); 

  const detectCountryCode = () => {
    const userCountryCode = navigator.language.split("-")[1];
    const formattedCountryCode = userCountryCode ? userCountryCode.toLowerCase() : "us";
    // Add more specific checks if needed based on the origin or location detection mechanism
    return formattedCountryCode; // Default to 'PT' if the country code is not detected
  };
  
  const handleOnZoomChanged = React.useCallback((map) => {
    const zoomLevel = map.getZoom();
    setViewDistance(zoomLevel);
  }, []);


  useEffect(() => {
    if (countryCodeNumber === '') {
      const detectedCountryCode = detectCountryCode();
      setCountryCode(detectedCountryCode);
      setCountryCodeNumber(getDialCode(detectedCountryCode));
    } else {
      setCountryCode(getCountryCode(countryCodeNumber));
    }
  }, [countryCodeNumber, countryCode]);

  const onMapClick = React.useCallback((event) => {
    setMarker({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    setLatitudeField(event.latLng.lat());
    setLongitudeField(event.latLng.lng());
  }, []);

  useEffect(() =>{
    console.log('latitudeField: ', 
    latitudeField, 
    ', longitudeField: ', 
    longitudeField,
    'phoneWithoutCountryCode: ', phoneWithoutCountryCode)
  },[latitudeField, longitudeField, postalCode, phoneWithoutCountryCode])

  const handleOnLoad = (map) => {
    // The google object is available here
    if (isGoogleLoaded) {
      google.maps.event.trigger(map, "resize");
      map.addListener("zoom_changed", () => handleOnZoomChanged(map));
    }
    
  };

  const restaurantDetails = useSelector(state => state.restaurantDetails);
  const { restaurants , isLoading, error} = restaurantDetails;

  const generateIntervalOptions = () => {
    const intervalOptions = [];
    for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 60; j += 15) {
            // Format the hour and minute values to have two digits
            const hour = i.toString().padStart(2, '0');
            const minute = j.toString().padStart(2, '0');
            const time = `${hour}:${minute}`;

            if (time >= '00:15' && time <= '08:00') {
                intervalOptions.push(time);
            }
        }
    }
    return intervalOptions;
};

const generateNumberOptions = () => {
  const numberOptions = [];
  for (let i = 0; i < 100; i++) {
      numberOptions.push(i);
  }
  return numberOptions;
};

// Usage:
const [numberOptions, setNumberOptions] = useState(generateNumberOptions());
const [intervalOptions, setIntervalOptions] = useState(generateIntervalOptions());

  useEffect(() => {
     
    if (creating_restaurant === 'no'){
      dispatch(fetchRestaurantDetails(restaurantId));
    } 
  },[dispatch, restaurant_id]);


  useEffect(() => {
    console.log('restaurants object:', restaurants); // Check the structure
    if (restaurant_id !== false) {
      setRestaurantId(restaurant_id);
    }
    if (restaurants && restaurants !== null) {
      setRestaurantName(restaurants.restaurant_name);
      setAddress(restaurants.adress);
      console.log('diff : ', restaurants.postal_code, ' !== ', postalCode)
      if (restaurants.postal_code !== postalCode && restaurants.postal_code !== undefined){
        setPostalCode(restaurants.postal_code);
      }
      setLatitudeField(parseFloat(restaurants.latitude));
      setLongitudeField(parseFloat(restaurants.longitude));
      setViewDistance(restaurants.view_distance);
      setMarker({
        lat:parseFloat(restaurants.latitude),
        lng:parseFloat(restaurants.longitude),
      })
      setNumberOfBabyChairs(restaurants.number_of_babychairs);
      setLimitPeopleReservation(restaurants.limit_people_reservation);
      setLimitBabyChairsReservation(restaurants.limit_baby_chairs_reservation);
      setEachTime(restaurants.each_time);
      setAutoAcceptLimit(restaurants.auto_accept_limit);
      setAcceptPeoplePerEachTime(restaurants.accept_people_per_each_time);
      setTimezone(restaurants.timezone);
      setAutoAcceptTimeLimit(restaurants.only_auto_accept_before_this_time);
      setMessageReminderTime(restaurants.send_message_reminder_to_customers);
      setAutoSetTables(restaurants.auto_set_tables);
      setTimeToCancelledWarning(restaurants.time_to_cancelled_warning);
      setPhoneWithoutCountryCode(restaurants.phone_number)
      setCountryCodeNumber(restaurants.country_code)
      setRestaurant_description(restaurants.restaurant_description)
      setReconfirmation_duration(restaurants.reconfirmation_duration)
      setReconfirmation_time_before_date(restaurants.reconfirmation_time_before_date)
    }
},[restaurants, restaurant_id])

  function checkEmptyStates() {
    let emptyStates = [];
  
    if (restaurantName === '') {
      emptyStates.push('Nome do restaurante');
    }
    if (!address) {
      emptyStates.push('Morada');
    }
    if (numberOfBabyChairs === '') {
      emptyStates.push('Numero de cadeiras para bebês');
    }
    if (!limitPeopleReservation) {
      emptyStates.push('Limite de pessoas por reserva');
    }
    if (!limitBabyChairsReservation) {
      emptyStates.push('Limite de cadeiras para bebês por reserva');
    }
    if (!autoAcceptLimit) {
      emptyStates.push('Aceitação automatica');
    }
    if (!eachTime) {
      emptyStates.push('Cada tempo');
    }
    if (!acceptPeoplePerEachTime) {
      emptyStates.push('Limite de pessoas por tempo');
    }
    if (!isValidNumber('+' + countryCodeNumber + phoneWithoutCountryCode)) {
      emptyStates.push('Telemóvel invalido');
    }
    console.log('isValidNumber: ', isValidNumber('+' + countryCodeNumber + phoneWithoutCountryCode))
  
    if (emptyStates.length > 0) {
      console.log('debugging here not ok')
      return 'Os seguintes campos não estão corretamente preenchidos: ' + emptyStates.join(', ');
      
    } else {
      console.log('debugging here ok')
      return 'ok_status';
    }
  }

  function checkForErrorsAndCreateRestaurant() {
    console.log('hereee in checkForErrorsAndCreateRestaurant')
    let status_error = checkEmptyStates();
    setErrorMessage(status_error);
    if (status_error === 'ok_status') {
      console.log('status_error: ', status_error)
      setShowError(false);
      const updatedRestaurantParams = {
        restaurant_name: restaurantName,
        adress: address,
        postal_code:postalCode,
        latitude:latitudeField,
        longitude:longitudeField,
        view_distance: viewDistance, 
        number_of_chairs: numberOfChairs,
        number_of_babychairs: numberOfBabyChairs,
        limit_people_reservation: limitPeopleReservation,
        limit_baby_chairs_reservation: limitBabyChairsReservation,
        auto_accept_limit: autoAcceptLimit,
        accept_people_per_each_time: acceptPeoplePerEachTime,
        each_time: eachTime,
        timezone: timezone,
        only_auto_accept_before_this_time:autoAcceptTimeLimit,
        send_message_reminder_to_customers:messageReminderTime,
        auto_set_tables:autoSetTables,
        time_to_cancelled_warning:timeToCancelledWarning,
        country_code:countryCodeNumber,
        phone_number:phoneWithoutCountryCode,
        restaurant_description:restaurant_description,
        reconfirmation_duration:reconfirmation_duration,
        reconfirmation_time_before_date:reconfirmation_time_before_date,
      };
      if (creating_restaurant === 'no'){
        dispatch(updateRestaurant(restaurantId, updatedRestaurantParams));
        if (steps) {
          setCreateRestaurantToFalse();
          handleNextStep();
        }
      } else {
        dispatch(createRestaurant(updatedRestaurantParams));
        setCreateRestaurantToFalse();
        handleNextStep();
      }
    } else {
      setShowError(true);
      console.log('it s false: ', status_error)
    }
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    checkForErrorsAndCreateRestaurant();
  };

  useEffect(() => {
    if (restaurantCreated === true) {
      checkForErrorsAndCreateRestaurant();
    }
  },[restaurantCreated])
    

  useEffect(() => {
    setRestaurant_params({
      restaurant_name: restaurantName,
      adress: address,
      postal_code:postalCode,
      latitude:latitudeField,
      longitude:longitudeField,
      view_distance: viewDistance, 
      number_of_chairs: numberOfChairs,
      number_of_babychairs: numberOfBabyChairs,
      limit_people_reservation: limitPeopleReservation,
      limit_baby_chairs_reservation: limitBabyChairsReservation,
      accept_people_per_each_time: acceptPeoplePerEachTime,
      each_time: eachTime,
      only_auto_accept_before_this_time:autoAcceptTimeLimit,
      auto_set_tables:autoSetTables,
      time_to_cancelled_warning:timeToCancelledWarning,
      country_code:countryCodeNumber,
      phone_number:phoneWithoutCountryCode,
    }) 
  }, [dispatch,restaurantName, address, numberOfChairs, numberOfBabyChairs, 
    limitPeopleReservation, limitBabyChairsReservation, autoAcceptLimit, eachTime, 
    acceptPeoplePerEachTime, autoSetTables, timeToCancelledWarning, ]);

    const handleChange = (e) => {
      const { name, value } = e.target;
      switch (name) {
        case "restaurant_name":
          setRestaurantName(value);
          break;
        case "adress":
          setAddress(value);
          break;
        case "postalCode":
          setPostalCode(value);
          break;
        case "number_of_chairs":
          setNumberOfChairs(value);
          break;
        case "number_of_babychairs":
          setNumberOfBabyChairs(value);
          break;
        case "limit_people_reservation":
          setLimitPeopleReservation(value);
          break;
        case "limit_baby_chairs_reservation":
          setLimitBabyChairsReservation(value);
          break;
        case "auto_accept_limit":
          setAutoAcceptLimit(value);
          break;
        case "accept_people_per_each_time":
          setAcceptPeoplePerEachTime(value);
          break;
        case "each_time":
          setEachTime(value);
          break;
        default:
          break;
      }
    };
  
  const handleIntervalTimeChange = (option) => {
    setEachTime(option);
  };

  const handleTimeCancelWarning = (value) => {
    setTimeToCancelledWarning(value);
  };
  
  const handleAutoAcceptTimeLimit = (value) => {
    setAutoAcceptTimeLimit(value);
  };

  const handleReconfirmation_time_before_date = (value) => {
    setReconfirmation_time_before_date(value);
  };

  const handleReconfirmation = (value) => {
    setReconfirmation_duration(value);
  };

  const handleMessageReminderTime = (value) => {
    setMessageReminderTime(value);
  };

  const MAX_CHARACTER_LIMIT = 10; 
  const handleInputPhoneChange = (e) => {
    const inputValue = e.target.value;
    console.log('phone input: ',inputValue)
    if (inputValue.length <= MAX_CHARACTER_LIMIT) {
      setPhoneWithoutCountryCode(inputValue);
    }
  };

  useEffect(() => {
    if (restaurants) {
      console.log('latitude: ', restaurants.latitude, 'longitude: ', restaurants.longitude)
       console.log('latitudeField: ', latitudeField, 'longitudeField: ', longitudeField)
    }
    
  },[restaurants])



  


  return (
    <Container>
      <Row>
        <Col sm={12} md={12} xl={12}>
          <CardHeader>
            <CardHeaderTitle>
              Definições
            </CardHeaderTitle>
          </CardHeader>
          {isLoading ? (
            <Loader />
          ) : error ? (
            <Messages variant="error">{error}</Messages>
          ) : 
          (
            <Form onSubmit={handleSubmit}>
              <div class='sub-title'>Número de clientes</div>
              <FormGroup>
                <InsideFormGroup>
                  <span>Horários disponíveis: permitir criação de reservas a cada</span>
                  <ScrollInputOptions
                    handleOptionsChange={handleIntervalTimeChange}
                    options={intervalOptions}
                    currentOption={eachTime}
                    style={{ minWidth: '50px' }}
                  />
                  <span>minutos.</span>
                 
                </InsideFormGroup>
              <FormGroup>
              <span>Cada horário disponível aceita até </span>
                  <Input
                    type="number"
                    id="accept_people_per_each_time"
                    name="accept_people_per_each_time"
                    value={acceptPeoplePerEachTime}
                    onChange={handleChange}
                  />
                  <span> pessoas, acumuladas entre todas as reservas. 
                    <div style={{fontSize:'12px'}}>
                    Para ver e alterar os horários disponíveis consultar secção Horários.
                    </div></span>
                
              </FormGroup>
              </FormGroup>
              <FormGroup>
                  <span>Número máximo de pessoas por grupo: </span>
                  <Input
                    type="number"
                    id="limit_people_reservation"
                    name="limit_people_reservation"
                    value={limitPeopleReservation}
                    onChange={handleChange}
                  />
                </FormGroup>
              <div class='sub-title'>Aceitação automática de reservas</div>
              <FormGroup>
                  <Label htmlFor="auto_accept_limit">Aceitar automaticamente grupos até </Label>
                  <Input
                    type="number"
                    id="auto_accept_limit"
                    name="auto_accept_limit"
                    value={autoAcceptLimit}
                    onChange={handleChange}
                    
                  />
                  <span> pessoas.</span>
                </FormGroup>
                <FormGroup> 
               <Label htmlFor="auto_accept_time_limit">Aceitar automaticamente reservas com antecedência mínima de</Label>
                <DropDownButton
                    options={intervalOptions}
                    handleOptionChange={handleAutoAcceptTimeLimit}
                    initialOption={autoAcceptTimeLimit}
                />
               </FormGroup> 
              <div class='sub-title'>Lembretes e reconfirmações</div>
              
              <FormGroup> 
                <InsideFormGroup>

                
               <span>
                Enviar um pedido de reconfirmação da reserva ao cliente quando esta for marcada com mais de  </span>
                <DropDownButton
                    options={numberOptions}
                    handleOptionChange={handleReconfirmation}
                    initialOption={reconfirmation_duration}
                />
                <span> de antecedência</span>
                </InsideFormGroup>
               </FormGroup>
               <FormGroup> 
                <InsideFormGroup>
                  <span>Enviar o pedido de reconfirmação ao cliente</span>
                  <DropDownButton
                      options={intervalOptions}
                      handleOptionChange={handleReconfirmation_time_before_date}
                      initialOption={reconfirmation_time_before_date}
                  />
                  <span>antes da hora da reserva.</span>
                </InsideFormGroup>
               </FormGroup> 
              <FormGroup>
                <InsideFormGroup>
                <span htmlFor="message_reminder">Enviar um lembrete da reserva ao cliente </span>
                  <ScrollInputOptions
                    handleOptionsChange={handleMessageReminderTime}
                    options={intervalOptions}
                    currentOption={messageReminderTime}
                  />
                  <span>
                    antes da hora da reserva.
                  </span>

                </InsideFormGroup>
                 
                </FormGroup>
                <FormGroup>
                  <InsideFormGroup>
                  <Label htmlFor="timeToCancel">Receber uma notificação de cancelamtento se este for feito a menos de </Label>
                  <ScrollInputOptions
                    handleOptionsChange={handleTimeCancelWarning}
                    options={intervalOptions}
                    currentOption={timeToCancelledWarning}
                  />
                  <span>
                  antes da hora da reserva.
                  </span>

                  </InsideFormGroup>
                  
                </FormGroup>


              <div class='sub-title'>Cadeiras de bebé</div>
              <FormGroup>
                  <Label htmlFor="number_of_babychairs">Quantas cadeiras de bebé existem no restaurante?</Label>
                  <Input
                    type="number"
                    id="number_of_babychairs"
                    name="number_of_babychairs"
                    value={numberOfBabyChairs}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="limit_baby_chairs_reservation">Quantas cadeiras de bebé podem ser reservadas por grupo?</Label>
                  <Input
                    type="number"
                    id="limit_baby_chairs_reservation"
                    name="limit_baby_chairs_reservation"
                    value={limitBabyChairsReservation}
                    onChange={handleChange}
                  />
                </FormGroup>
              <div class='sub-title'>Atribuição automática de mesas</div>
              <FormGroup>
                <div style={{display:'flex',flexWrap:'nowrap'}}> 
                  <SwitchOnAndOff isOn={autoSetTables} handleToggle={() => setAutoSetTables(!autoSetTables)}>
                  </SwitchOnAndOff>
                  <div style={{marginLeft:'5px'}}>Atribuir mesas automaticamente às reservas recebidas.</div>
                </div>
                
                </FormGroup>

                
               

                <FormGroup>
                  <StyledSelect value={timezone} onChange={e => setTimezone(e.target.value)}>
                      {timezones.map(tz => <option key={tz} value={tz}>{tz}</option>)}
                  </StyledSelect>
                </FormGroup>

                {steps === false?
                <>
                {errorMessage !== 'ok_status' && 
                  <div style={{color:'red', marginBottom:'10px'}}> {errorMessage} </div>
                }
                <Button type="submit">Guardar</Button>
                </>
                :
                <></>
                }
            </Form>
          )}
        </Col>
      </Row>
      </Container>
      );
      }

export default RestaurantSettingsUpdate;