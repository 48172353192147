import React, { useState, useEffect } from 'react'
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { register } from '../actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import Messages from '../components/Messages'
import Loader from '../components/Loader'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey,  faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Title = styled.h3`
  margin: 20px;
  text-transform: uppercase;
`;

const AbsoluteKeySquare = styled.div`
  position: absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  border-radius: 4px 0 0 4px;
  background-color: #f2f2f2;
  border: solid 1px #cfcfcf;
  top: 0px;
  left: 0px;
  width: 50px;
  height: 38px;
  padding: 1px;
  font-size: 18px;
  text-align: center;
  &:hover {
    background-color: #e8e8e8;
    border-color: #76d6c5;
  }
`;

const StyledCard = styled.div`
  border-radius:5px;
  max-width: 500px;
  padding:15px;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width:100%;
`;

function CreateAccountScreen() {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('')
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [disableButton, setdisableButton] = useState(true);
    const [showPasswords, setShowPasswords] = useState(true);

    const dispatch = useDispatch()
    const userRegister = useSelector(state => state.userRegister)
    const { isLoading, error} = userRegister

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const location = useLocation() 
    const navigate = useNavigate()
    const redirect = location.search ? location.search.split('=')[1] : '/'


    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
      };
    
    const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    };

    const handleSubmit = (event) => {
    event.preventDefault();
    // code to submit form goes here
    dispatch(register(name, email, password))
    };

    useEffect(()=> {
        if (password !== confirmPassword || password == '') {
            setPasswordsMatch(false);
            setdisableButton(true);
        } else {
            setPasswordsMatch(true);
            setdisableButton(false);
        }
      },[password, confirmPassword])

    useEffect(() => {
      if(userInfo) {
        navigate('/')
    }
    },[userInfo])

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <StyledCard border="light" className="rounded-lg p-3">
          <Form onSubmit={handleSubmit}>
            {error && <Messages variant='danger'>{error}</Messages>}
            {isLoading && <Loader />}
            <Title>Criar nova conta</Title>
            <Form.Group controlId="formBasicEmail">

            <Form.Label>Nome de utilizador</Form.Label>
                <Form.Control 
                    type="text" 
                    placeholder="Nome de utilizador"
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                />
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                 type="email"
                 placeholder="E-mail" 
                 value={email}
                 required
                 onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Text className="text-muted">
                
                </Form.Text>
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
                <div style={{ position: 'relative' }}>
                <Form.Control required type={showPasswords ? 'password' : 'text'} placeholder="Criar uma palavra-passe" value={password} onChange={handlePasswordChange} style={{ paddingLeft: '60px' }} />
                    <AbsoluteKeySquare style={{cursor: 'pointer'}} onClick={() => setShowPasswords(!showPasswords)}>
                    {showPasswords ?
                      <FontAwesomeIcon size='lg' icon={faEyeSlash} style={{color: "#00ad74"}}/>
                    :
                      <FontAwesomeIcon size='lg' icon={faEye} style={{color: "#00ad74"}}/>
                    }
                    </AbsoluteKeySquare>
                </div>
            </Form.Group>
            <Form.Group controlId="formBasicConfirmPassword">
               
                 <div style={{ position: 'relative' }}>
                 <Form.Control
                 type={showPasswords ? 'password' : 'text'}
                 placeholder="Confirmar a palavra-passe" 
                 value={confirmPassword} 
                 required
                 onChange={handleConfirmPasswordChange}
                 style={{ paddingLeft: '60px' }}/>
                    <AbsoluteKeySquare style={{cursor: 'pointer'}} onClick={() => setShowPasswords(!showPasswords)}>
                    {showPasswords ?
                      <FontAwesomeIcon size='lg' icon={faEyeSlash} style={{color: "#00ad74"}}/>
                    :
                      <FontAwesomeIcon size='lg' icon={faEye} style={{color: "#00ad74"}}/>
                    }
                    </AbsoluteKeySquare>
                </div>
                {!passwordsMatch && <Form.Text className="text-danger">As palavras-passe não coincidem.</Form.Text>}
            </Form.Group>

            <Button  
            className="mt-3" 
            variant="primary" 
            type="submit"
            disabled={disableButton}
            >
                Criar conta
            </Button>
              <Card.Text className="mt-3 text-center">
                Já tem uma conta? 
                <Link to={'/login/null/null'} style={{marginLeft:'5px'}}>Entrar</Link>
              </Card.Text>
            </Form>
          </StyledCard>
          
        </Col>
      </Row>
    </Container>
  )
}

export default CreateAccountScreen