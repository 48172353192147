import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import TermsAndConditions from './TermsAndConditions'

function Footer() {
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

    const handleTermsAndConditionsFalse = () => {
        setShowTermsAndConditions(false);
      };
  return (
        <footer>
            <Container>
                <Row>
                    <Col className='text-center py-3'>
                    Copyright &copy; Soup
                    <br></br>
                    <a onClick={() => setShowTermsAndConditions(true)} style={{ cursor: 'pointer', color: '#59d4f0'}}>
                          Termos e condições
                        </a>
                    </Col>
                </Row>
                <TermsAndConditions
                    showTermsAndConditions={showTermsAndConditions}
                    setSetShowTermsAndConditions={handleTermsAndConditionsFalse}
                />
            </Container>
            <div className='space_for_menu'></div>
        </footer>
  )
}

export default Footer