import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Card, Button, Row, Col, Modal, CardText } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getRestaurantSubscription } from '../actions/userActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUtensils, faEnvelope, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { createRestaurant, fetchRestaurantDetails } from '../actions/restaurantActions';
import RestaurantCreation from '../screens/RestaurantCreation'

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledCard = styled(Card)`
  margin: 10px 0;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  overflow: hidden;
  border:0;
`;

const CardTop = styled.div`
  padding: 10px;
  background-color: white;
  justify-content:center;
  background-color: #FAF6E9;
`;

const CardBottom = styled.div`
  padding: 10px;
  
`;

const StyledRow = styled(Row)`
  height: 100%;
  align-items: center;
  width: 100%;
  position:relative;
  margin-left:0;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify ? props.justify : "start"};
`;

const StyledColForIcons = styled(Col)`
  align-items: center;
  position:relative;
  justify-content:start;
  font-size:2rem;
  font-weight:bold;
  color:#EE754C;
`;

const Styledh6 = styled.h6`
  font-size: 1rem;
  margin-left:3px;
`;


const Smallh6 = styled.h6`
  font-size: 0.9rem;
  margin: 0;
  color:#99B0AD;
  font-weight:bold;
`;

const StyledIconText = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px; 
  position:absolute;
  top:3px;
  left:2rem;
`;
const ModalCard = styled(Card)`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: start;
  margin-bottom: 10px;
`;

const CheckIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 800px;
    width: 100%;
  }
  .modal-content {
    background-color: #fffaf5;
  }
`;

const StyledButton = styled.button`
  background-color: #99B0AD; /* Gray background color */
  color: white; /* Text color */
  border: none; /* No border */
  border-radius: 5px; /* Slightly rounded corners */
  padding: 10px 20px; /* Padding for a comfortable size */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */

  &:hover {
    background-color: #7e9895; /* Slightly darker gray on hover */
  }

  &:active {
    background-color: #6a837f; /* Even darker gray when clicked */
  }

  &:disabled {
    background-color: #ccd4d3; /* Lighter gray when disabled */
    cursor: not-allowed; /* Indicate non-clickable state */
  }
`;

const ModalHeader = styled(Modal.Header)`
  border-bottom: 1px solid black;
`;
const ModalTitle = styled(Modal.Title)`
  margin-left: 10px;
  color: ${props => props.selected ? "white" : "black"};
`;
const ModalTitleText = styled.span`
  color: ${props => props.selected ? "white" : "black"};
`;


const ModalCardText = styled(Card.Text)`
  color: ${props => props.selected ? "white" : "black"};
`;

function RestaurantsHeader() {
  const firstOption = ["Seguir tutorial",
  "Ao criar o restaurante com o tutorial ativado, explicamos como configurar todas as opções ao seu gosto."]
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(firstOption);
  const [showHeader, setShowHeader] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const restaurant_sub = useSelector((state) => state.restaurant_subscription);
  const { restaurantSubscription } = restaurant_sub;

  const manager_info = useSelector(state => state.crudManager)
  const { managerInfo } = manager_info

  useEffect(() => {
    if (restaurantSubscription && restaurantSubscription[0].account_type === 'not_vip') {
      setShowHeader(false);
      console.log('inside useeffect restaurant header: ', restaurantSubscription[0].account_type)
    } else {
      setShowHeader(true);
    }
  }, [restaurantSubscription]);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const handleSelect = (option) => {
    if (selectedOption === null || option[0] !== selectedOption[0]) {
      setSelectedOption(option);
    } else if (option[0] === selectedOption[0]) {
      setSelectedOption(null);
    }
};

  const handleCreateRestaurant = () => {
    console.log('selectedOption :', selectedOption)
    console.log('firstOption :', firstOption)
    dispatch(createRestaurant('reset', true));
    dispatch(fetchRestaurantDetails('reset', true));
    if (selectedOption[0] === firstOption[0]) {
      navigate(`/restaurant_creation/${'yes'}/${'null'}/${'null'}`);
    } else {
      navigate(`/restaurant_creation/${'no'}/${'null'}/${'null'}`);
    }
  };

  return (
    <StyledCard className="p-0">
      {managerInfo && showHeader? 
      <> 
      <CardTop>
        <StyledRow>
          <StyledCol xs={12} md={12}>
              Gerente convidado por {managerInfo.boss.name} 
          </StyledCol>
        </StyledRow>
      </CardTop>
      </>
      :
      <>
      {restaurantSubscription && showHeader?
      <>
      <CardTop>
        <StyledRow>
          <StyledCol xs={12} md={12}>
            { restaurantSubscription && restaurantSubscription[0].trial_days > 1 && 
              <Styledh6>Experimente já! {restaurantSubscription[0].trial_days} dias grátis no seu primeiro restaurante!!</Styledh6>
            }
          </StyledCol>
          <StyledCol xs={12} md={12}>
            <StyledRow>
              <StyledColForIcons xs={6}>
                 Restaurantes
              </StyledColForIcons>
              <StyledCol xs={6} justify="flex-end">
                <ActionButtons>
                  <StyledButton  onClick={() => setShowModal(true)}> 
                    <Smallh6>Novo restaurante <FontAwesomeIcon size='lg' icon={faPlus} style={{color:'#FAF6E9'}} className="ml-2"/> </Smallh6>
                  </StyledButton>
                 
                </ActionButtons>
              </StyledCol>
            </StyledRow>
          </StyledCol>
        </StyledRow>
      </CardTop>
      </>
      :
      <>
      <CardTop>
        <StyledRow>
          <StyledCol xs={12} md={12}>
            Aplicação em desenvolvimento. Mantenha-se a par das novidades!
          </StyledCol>
        </StyledRow>
      </CardTop>
      </>
      }   
      </>
      }
      
    </StyledCard>
  )
}

export default RestaurantsHeader;
